.color-gray-dark {
  color: #828282; }

.color-gray-strong {
  color: #706e6e; }

.color-gray-light {
  color: #7f9494; }

.ul-1 {
  list-style: circle; }

.list-style {
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 18px;
  color: #621132;
  list-style-type: initial; }

.p1 {
  color: #5d5d5d; }

.span1 {
  color: #621132; }

.padding-left-0 {
  padding-left: 16px; }

.invalid-feedback-custom {
  color: #dc3545;
  border: 1px solid #dc3545;
  padding: 8px;
  margin: 5px 0;
  display: block;
  text-align: center;
  background-color: rgba(220, 53, 69, 0.1); }

.ac-card-top {
  border: solid 1px #0f4c42;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }

.ac-card-bottom {
  border: solid 1px #0f4c42;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff; }

.ac-cell {
  margin: 0.5em; }
  .ac-cell strong {
    color: #828282; }
  .ac-cell span {
    color: #7f9494; }

.ac-subtitle {
  font-size: 18px;
  margin-bottom: 25px;
  color: #706e6e; }

.ac-label {
  font-size: 14px;
  font-weight: bold;
  color: #828282;
  margin-top: 1.5em; }
  .ac-label span {
    color: #7f9494;
    font-weight: normal;
    display: block;
    margin-top: 0.5em; }
  .ac-label .input-radio {
    display: flex;
    align-items: center; }
    .ac-label .input-radio span {
      margin: 0;
      margin-right: 12px; }
  .ac-label input[type="radio"] {
    height: 14px;
    width: 14px;
    margin: 1px; }

.select2-container {
  width: 100% !important; }

.custom-file-label::after {
  content: "Subir archivo"; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #621132 !important;
  background-color: #621132 !important; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border: none !important;
  border-radius: 2rem !important;
  padding: 5 10px !important;
  color: #707070; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #621132 !important; }

.daterangepicker td {
  margin: 0;
  padding: 0; }

.subtitle2 {
  font-size: 19px;
  color: #a0a0a0;
  font-weight: bold;
  text-align: start; }

.bullet-point {
  padding-left: 4%; }

.title2 {
  font-size: 24px;
  font-weight: bold;
  color: #0f4c42;
  line-height: 0.91;
  margin: 0;
  text-align: start; }

.subtitle3 {
  font-size: 15px;
  font-weight: bold;
  color: #0f4c42; }

.subtitle4 {
  font-size: 12px;
  font-weight: bold;
  color: #626262; }

.f-description {
  color: #a0a0a0;
  font-size: 12px;
  font-weight: bold; }

.title3 {
  font-size: 22px;
  font-weight: bold;
  color: #0f4c42; }

.subtitle5 {
  font-size: 16px;
  font-weight: 600;
  color: #a0a0a0; }

.title-registro {
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  color: #0f4c42; }

.subtitle6 {
  font-size: 16px;
  font-weight: 600;
  color: #a0a0a0;
  text-align: left; }

.elige {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  color: #0f4c42; }

.title-folio {
  font-size: 22px;
  line-height: 0.91;
  font-weight: bold;
  text-align: left;
  color: #0f4c42; }

.subtitle-dependeci {
  font-size: 19px;
  font-weight: bold;
  line-height: 1.05;
  text-align: left;
  color: #a0a0a0; }

.titles-cards {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: #0f4c42; }

.subtitles-cards {
  font-size: 12px;
  font-weight: 5  00;
  line-height: 1.67;
  color: #797979; }

.title-history {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #0f4c42; }

.brownish-grey-four-24 {
  color: #737373 !important;
  font-size: 24px;
  font-weight: bold;
  text-align: center; }

.link-file {
  color: #009288;
  font-weight: bold;
  position: relative; }
  .link-file::before {
    content: url("/media/assets/images/doc-turqueza.svg");
    position: absolute;
    left: -17px; }

.legend-green {
  font-size: 15px;
  font-weight: bold;
  color: #0f4c42; }

.placeholder1 {
  font-size: 14px;
  font-weight: bold;
  color: #c1c7d0; }

.lineTimeContainer {
  position: relative; }
  .lineTimeContainer::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    left: 17px;
    top: 8px;
    background: #e7e7e7; }
  .lineTimeContainer li {
    position: relative;
    list-style: none;
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
    cursor: pointer; }
  .lineTimeContainer li::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    left: -30px;
    top: 4px;
    border-radius: 50%;
    background: #e7e7e7; }
  .lineTimeContainer .child {
    margin-bottom: 0; }
  .lineTimeContainer .child::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 55px;
    top: 6px;
    left: -25px;
    background: white; }
  .lineTimeContainer .selectedItem::after {
    border: 2px solid white !important;
    box-shadow: 0 0 0 2px #621132; }
  .lineTimeContainer .selectedItem {
    color: #621132;
    font-weight: bold; }
  .lineTimeContainer .completedItem::after {
    content: url("/media/assets/images/checkboxWhite.svg");
    background: #621132;
    border: 2px solid #621132;
    text-align: center;
    padding: 3px 0; }

.newhr {
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
  border: 0 !important;
  border-top: 1px solid #0f4c42 !important; }

.newhr2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #0f4c42; }

.card-back1 {
  min-width: 260px;
  max-width: 100%;
  border-radius: 14px;
  border: 10px;
  box-shadow: 0 2px 4px 0 rgba(12, 15, 13, 0.39);
  background-color: #fff;
  text-align: center;
  padding: 3%;
  margin: 1%; }

.card-gestion {
  border-radius: 14px;
  border: 4px;
  background-color: #fff;
  padding: 3%;
  box-shadow: 0 2px 4px 0 rgba(12, 15, 13, 0.39); }

.btn-06 {
  display: block;
  margin-bottom: 15px;
  min-height: 28px;
  border-radius: 12px;
  background-color: #621132;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff !important;
  padding: 10px 50px;
  line-height: normal;
  text-align: center;
  margin: 3%; }

.btn-006 {
  display: block;
  margin-bottom: 15px;
  min-height: 28px;
  border-radius: 12px;
  background-color: #621132;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff !important;
  padding: 10px 50px;
  line-height: normal;
  text-align: center;
  margin: 3%; }

.table-margin {
  margin-top: 5%;
  margin-bottom: 5%; }

.btn-edit {
  display: block;
  margin-bottom: 15px;
  min-height: 28px;
  border-radius: 12px;
  background-color: #621132;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff !important;
  padding: 10px 50px;
  line-height: normal;
  text-align: center;
  margin: 3%; }

.btn-arrow {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  min-height: 66px;
  font-size: 16px;
  margin: 0 18px;
  background-color: #621132; }
  .btn-arrow:hover {
    color: white; }
  .btn-arrow span {
    font-weight: 100;
    font-size: 15px; }
  .btn-arrow::after {
    content: "";
    position: absolute;
    border: 33px solid #621132;
    border-left-width: 17px;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    right: -50px;
    top: 0; }
  .btn-arrow::before {
    content: "";
    position: absolute;
    left: -17px;
    border: 33px solid #621132;
    border-left-width: 17px;
    border-right-width: thin;
    border-left-color: transparent;
    border-right-color: transparent; }

.btn-arrow-disabled {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  min-height: 66px;
  font-size: 16px;
  margin: 0 18px;
  pointer-events: none;
  opacity: 0.65;
  background-color: #9b9b9b; }
  .btn-arrow-disabled:hover {
    color: white; }
  .btn-arrow-disabled span {
    font-weight: 100;
    font-size: 15px; }
  .btn-arrow-disabled::after {
    content: "";
    position: absolute;
    border: 33px solid #9b9b9b;
    border-left-width: 17px;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    right: -50px;
    top: 0; }
  .btn-arrow-disabled::before {
    content: "";
    position: absolute;
    left: -17px;
    border: 33px solid #9b9b9b;
    border-left-width: 17px;
    border-right-width: thin;
    border-left-color: transparent;
    border-right-color: transparent; }

.btn-arrow-left {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  min-height: 66px;
  font-size: 16px;
  background-color: #621132; }
  .btn-arrow-left:hover {
    color: white; }
  .btn-arrow-left span {
    font-weight: 100;
    font-size: 15px; }
  .btn-arrow-left::after {
    content: "";
    position: absolute;
    border: 33px solid #621132;
    border-left-width: 17px;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    right: -50px;
    top: 0; }
  .btn-arrow-left::before {
    content: "";
    position: absolute;
    height: 66px;
    width: 33px;
    left: -33px;
    border-radius: 66px 0 0 66px;
    background: #621132; }

.btn-arrow-rigth {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  min-height: 66px;
  font-size: 16px;
  background-color: #621132; }
  .btn-arrow-rigth:hover {
    color: white; }
  .btn-arrow-rigth span {
    font-weight: 100;
    font-size: 15px; }
  .btn-arrow-rigth::before {
    content: "";
    position: absolute;
    left: -17px;
    border: 33px solid #621132;
    border-left-width: 17px;
    border-right-width: thin;
    border-left-color: transparent;
    border-right-color: transparent; }
  .btn-arrow-rigth::after {
    content: "";
    position: absolute;
    height: 66px;
    width: 33px;
    right: -27px;
    border-radius: 0 66px 66px 0;
    background: #621132; }

.btn-arrow-left-disabled {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  min-height: 66px;
  font-size: 16px;
  background-color: #9b9b9b; }
  .btn-arrow-left-disabled:hover {
    color: white; }
  .btn-arrow-left-disabled span {
    font-weight: 100;
    font-size: 15px; }
  .btn-arrow-left-disabled::before {
    content: "";
    position: absolute;
    left: -17px;
    border: 33px solid #9b9b9b;
    border-left-width: 17px;
    border-right-width: thin;
    border-left-color: transparent;
    border-right-color: transparent; }
  .btn-arrow-left-disabled::after {
    content: "";
    position: absolute;
    height: 66px;
    width: 33px;
    right: -27px;
    border-radius: 0 66px 66px 0;
    background: #9b9b9b; }

.styles-table {
  margin-bottom: 6%; }

.title-collapse {
  font-size: 13px;
  line-height: 13px;
  color: #0f4c42;
  font-weight: 600; }

.etapas {
  font-size: 13px;
  color: #a0a0a0;
  font-weight: 600;
  position: relative; }
  .etapas::after {
    content: "";
    position: absolute;
    right: -15px;
    top: 3px;
    display: block;
    border: 5px solid #a0a0a0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent; }

.etapas:hover {
  color: #621132; }

.etapas-complete {
  color: #621132 !important; }

.etapasComplete {
  color: #621132 !important; }

.titles-footer {
  font-size: 12px;
  color: #fff;
  margin-bottom: 0; }

.titles-footer2 {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0; }

.links-footer {
  font-size: 12px;
  font-weight: bold;
  color: #fff; }

.p-footer {
  position: absolute;
  bottom: 0;
  width: 98.4%; }

.bgf {
  background-color: #343434; }

.title-contract {
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #0f4c42; }

.title-contract-green {
  color: #621132; }

.title-contract-03 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  color: #0f4c42; }

.subtitle-contract {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #a0a0a0; }

.subtitle-contract-03 {
  font-size: 16px;
  font-weight: 600;
  color: #a0a0a0; }

.li-contract {
  font-size: 15px;
  line-height: 1.5;
  color: #5d5d5d;
  list-style: initial;
  font-weight: 500;
  margin-bottom: 3%; }

.li-contract-sp {
  font-weight: bold;
  color: #666666; }

.hr1 {
  margin-bottom: 0; }

.mb-complete {
  margin-bottom: 10%; }

.mb-complete2 {
  margin-bottom: 5%; }

.mt-complete {
  margin-top: 10%; }

.miul .mili {
  color: #3c3c3c;
  /* set color of list item text */
  display: inline-block;
  list-style: none;
  /* Give the bullet room on the left hand side */
  padding: 0;
  position: relative;
  /*
      width: 5px;
      height: 5px;
    */ }

.miul .mili::before {
  color: #621132;
  /* color of bullet or square */
  content: "\2022";
  /* Unicode of character to precede the list item */
  display: inline-block;
  font-size: 2em;
  /* use em or % */
  left: -1.1225em;
  /* use em, line up bullet flush with left hand side */
  position: absolute;
  /* Set the bullet positioned absolutely top left */
  top: -0.3em;
  /* use em or % */ }

.miul {
  margin-left: 0.2em;
  padding-left: 0em;
  margin-bottom: 0.5em;
  list-style: none; }

.text-03 {
  font-size: 13px;
  text-align: justify;
  color: #676565; }

.input-title {
  font-size: 12px;
  font-weight: 500;
  color: #5d5d5d; }

.p-03 {
  font-size: 12px;
  font-weight: 500;
  color: #5d5d5d; }

.link-03 {
  font-weight: bold;
  color: #621132; }

.folio-style {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: normal;
  color: #5d5d5d; }

.title-card {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: normal;
  color: #0f4c42; }

.dates-contracts {
  font-size: 12px;
  font-weight: bold;
  color: #a0a0a0; }

.dates-contracts-2 {
  font-size: 12px;
  font-weight: 500;
  color: #5d5d5d; }

.title-table {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #0f4c42; }

.watermark {
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #a0a0a0; }

.folio {
  font-size: 19px;
  font-weight: bold;
  line-height: 1.74;
  text-align: center;
  color: #5d5d5d; }

.folio-green {
  color: #621132; }

.subtitle-folio {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #a0a0a0; }

.pll {
  padding-left: 2%; }

.pagination {
  justify-content: flex-start !important; }

.paginate_button {
  margin-right: 8px; }

.padding-tb {
  padding-top: 3rem;
  padding-bottom: 8rem; }

.space-empty {
  margin-top: 8em;
  margin-bottom: 15em; }

.space-empty2 {
  padding-bottom: 6em; }

.space-empty3 {
  padding-top: 6em;
  padding-bottom: 5em; }

.card-style-contract {
  border-radius: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 2px #eaeaea;
  background-color: #ffffff; }

.card3 {
  border: solid 1px #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  -webkit-border-bottom-right-radius: 25px;
  -webkit-border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  width: 240em; }

.mb-card {
  margin-bottom: 6%; }

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */ }

.formulario-informacion .custom-label {
  padding-top: 2px;
  color: #621132 !important;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0; }

.shamrock-16 {
  font-size: 16px;
  color: #621132;
  font-weight: 500;
  text-align: center; }

.style-404 {
  font-size: 384px;
  font-weight: bold;
  color: #a0a0a0; }

.page-not-found {
  font-size: 96px;
  color: black; }

.pagination .current {
  background: #621132 !important;
  border-radius: 8px; }

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em; }

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: ''; }

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc; }

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent; }

.daterangepicker.opensleft:before {
  right: 9px; }

.daterangepicker.opensleft:after {
  right: 10px; }

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.daterangepicker.opensright:before {
  left: 9px; }

.daterangepicker.opensright:after {
  left: 10px; }

.daterangepicker.drop-up {
  margin-top: -7px; }

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc; }

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff; }

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none; }

.daterangepicker.single .drp-selected {
  display: none; }

.daterangepicker.show-calendar .drp-calendar {
  display: block; }

.daterangepicker.show-calendar .drp-buttons {
  display: block; }

.daterangepicker.auto-apply .drp-buttons {
  display: none; }

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px; }

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px; }

.daterangepicker .drp-calendar.right {
  padding: 8px; }

.daterangepicker .drp-calendar.single .calendar-table {
  border: none; }

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px; }

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer; }

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff; }

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse; }

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit; }

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc; }

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999; }

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0; }

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px; }

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0; }

.daterangepicker td.start-date.end-date {
  border-radius: 4px; }

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff; }

.daterangepicker th.month {
  width: auto; }

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through; }

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default; }

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%; }

.daterangepicker select.yearselect {
  width: 40%; }

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px; }

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative; }

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed; }

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle; }

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px; }

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px; }

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd; }

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd; }

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0; }

.daterangepicker.show-calendar .ranges {
  margin-top: 8px; }

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%; }

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer; }

.daterangepicker .ranges li:hover {
  background-color: #eee; }

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto; }
  .daterangepicker .ranges ul {
    width: 140px; }
  .daterangepicker.single .ranges ul {
    width: 100%; }
  .daterangepicker.single .drp-calendar.left {
    clear: none; }
  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left; }
  .daterangepicker {
    direction: ltr;
    text-align: left; }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0; }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .daterangepicker .drp-calendar.right {
    margin-left: 0; }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px; }
  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left; } }

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto; }
  .daterangepicker .ranges {
    float: left; }
  .daterangepicker.rtl .ranges {
    float: right; }
  .daterangepicker .drp-calendar.left {
    clear: none !important; } }

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease; }

.component-fade-enter, .component-fade-leave-to {
  opacity: 0; }
